
import React, { useContext,useReducer } from "react";

// libs


// custom
import reducer from "../reducers/main_reducer";
import {
	SIDEBAR_OPEN,
	SIDEBAR_CLOSE,
	PROFILE_NAVBAR_STATUS,
	CHANGE_THEME,
	SET_PROPERTY,
	PROPERTY_DELETE_MODAL_OPEN,
	PROPERTY_DELETE_MODAL_CLOSE,
	PROPERTY_CHANGES_STATUS,
	SET_DEAL,
	DEAL_DELETE_MODAL_OPEN,
	DEAL_DELETE_MODAL_CLOSE,
	DEAL_CHANGES_STATUS,
	SET_MATCH,
	MATCH_DELETE_MODAL_OPEN,
	MATCH_DELETE_MODAL_CLOSE,
	MATCH_CHANGES_STATUS,
	SET_BLOG_CATEGORY,
	AGENT_INVENTORY_MODAL_OPEN,
	AGENT_INVENTORY_MODAL_CLOSE,
} from "../utils/actions";


const initialContext= {
	//sidebar
	isSidebarOpen: false,
	openSidebar: (() => {}),
	closeSidebar: (() => {}),
	//profile navbar
	profileNavbarOp : null,
	setProfileNavbarOp: (() => {}),
	// theme
	theme: {
        navShow: true,
        navbg: "transparent",
        footerShow: true,
        bannerShow: true,
    },
	changeTheme: (() => {}),
	// property
	currentProperty : {},
	setProperty : (() => {}),
	isDeletePropertyModalOpen : false,
	openDeletePropertyModal : (() => {}),
	closeDeletePropertyModal : (() => {}),
	propertyChangesStatus : false,
	propertyChangesEvent : (() => {}),
	// deals
	currentDeal : {},
	setDeal : (() => {}),
	isDeleteDealModalOpen : false,
	openDeleteDealModal : (() => {}),
	closeDeleteDealModal : (() => {}),
	dealChangesStatus : false,
	dealChangesEvent : (() => {}),
	// match
	currentMatch : {},
	setMatch : (() => {}),
	isDeleteMatchModalOpen : false,
	openDeleteMatchModal : (() => {}),
	closeDeleteMatchModal : (() => {}),
	matchChangesStatus : false,
	matchChangesEvent : (() => {}),
	// blog category
	currentBlogCategory : 0,
	setCurrentBlogCategory : (() => {}),
	// agent inventory
	isAgentInventoryModalOpen : false,
	openAgentInventoryModal : (() => {}),
	closeAgentInventoryModal : (() => {}),
};

const MainContext = React.createContext(initialContext);

export const MainProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialContext);

	// sidebar
	const openSidebar = () => {
		dispatch({ type: SIDEBAR_OPEN });
	};
	const closeSidebar = () => {
		dispatch({ type: SIDEBAR_CLOSE });
	};
	// profile navbar option
	const setProfileNavbarOp = (currentOp) => {
		dispatch({ type: PROFILE_NAVBAR_STATUS , payload : currentOp });
	}
	// theme
	const changeTheme = (currentTheme) => {
		dispatch({ type: CHANGE_THEME, payload: currentTheme });
	};
	// property
	const setProperty = (property) => {
		dispatch({ type: SET_PROPERTY, payload: property });
	};
	const openDeletePropertyModal = () => {
		dispatch({ type: PROPERTY_DELETE_MODAL_OPEN });
	}
	const closeDeletePropertyModal = () => {
		dispatch({ type: PROPERTY_DELETE_MODAL_CLOSE });
	}
	const propertyChangesEvent = (propStatus) => {
		dispatch({ type: PROPERTY_CHANGES_STATUS ,  payload: propStatus });
	}
	// deal
	const setDeal = (deal) => {
		dispatch({ type: SET_DEAL, payload: deal });
	};
	const openDeleteDealModal = () => {
		dispatch({ type: DEAL_DELETE_MODAL_OPEN });
	}
	const closeDeleteDealModal = () => {
		dispatch({ type: DEAL_DELETE_MODAL_CLOSE });
	}
	const dealChangesEvent = (dealStatus) => {
		dispatch({ type: DEAL_CHANGES_STATUS ,  payload: dealStatus });
	}
	// deal
	const setMatch = (match) => {
		dispatch({ type: SET_MATCH, payload: match });
	};
	const openDeleteMatchModal = () => {
		dispatch({ type: MATCH_DELETE_MODAL_OPEN });
	}
	const closeDeleteMatchModal = () => {
		dispatch({ type: MATCH_DELETE_MODAL_CLOSE });
	}
	const matchChangesEvent = (matchStatus) => {
		dispatch({ type: MATCH_CHANGES_STATUS ,  payload: matchStatus });
	}
	// blog category
	const setCurrentBlogCategory =  (blogCategory) => {
		dispatch({ type: SET_BLOG_CATEGORY ,  payload: blogCategory });
	};
	// agent inventory
	const openAgentInventoryModal = () => {
		dispatch({ type: AGENT_INVENTORY_MODAL_OPEN });
	}
	const closeAgentInventoryModal = () => {
		dispatch({ type: AGENT_INVENTORY_MODAL_CLOSE});
	}


	return (
		<MainContext.Provider
			value={{
				...state,
				//sidebar
				openSidebar,
				closeSidebar,
				//navbar profile
				setProfileNavbarOp,
				// theme
				changeTheme,
				// property
				setProperty,
				openDeletePropertyModal,
				closeDeletePropertyModal,
				propertyChangesEvent,
				// deal
				setDeal,
				openDeleteDealModal,
				closeDeleteDealModal,
				dealChangesEvent,
				// match
				setMatch,
				openDeleteMatchModal,
				closeDeleteMatchModal,
				matchChangesEvent,
				// blog category
				setCurrentBlogCategory,
				// agent inventory
				openAgentInventoryModal,
				closeAgentInventoryModal
			}}
		>
			{children}
		</MainContext.Provider>
	);
};
// make sure use
export const useMainContext = () => {
	return useContext(MainContext);
};
